@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family) !important;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#root {
  font-size: 14px;
  color: #404452;
}

.modal-dialog {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);;

  .modal-content {
    border: 0;
  }
}

.modal-backdrop {
  opacity: .3!important;
}

#root .dropdown {
  a {
    color: #1A1B25;
    text-decoration: none;
  }
}

#root .dropdown-menu {
  border-radius: 11px;
  border: 0;
  padding: 10px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);

  .dropdown-header {
    padding: 10px;
    text-transform: uppercase;
  }

  .dropdown-item {
    padding: 10px;
    border-radius: 4px;

    &:hover {
      background-color: #EEEFF2;
    }
  }
}

.full-width {
  width: 100% !important;
}

.bold {
  font-weight: 700;
}

.bolder {
  font-weight: 900;
}

.gray {
  color: #797F8A;
}

.Button {
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #EEEFF2;
  padding: 5px 14px;
  font-size: 16px;
}

.Button-primary {
  color: #fff;
  background-color: #FF524B;
  border-radius: 6px;
  border-color: #FF524B;
}

.Button-danger {
  border-color: #EB4956;
  color: #EB4956
}

.Button-link {
  color: #797F8A;
  border: 0;
}

.Button-link-primary {
  padding: 0;
  border: 0;
  color: #FF524B;

  &:hover {
    text-decoration: underline;
  }
}

.valign-middle {
  align-items: center;
  vertical-align: middle;
}

button.Action-Menu-btn {
  background-color: #fff;
  border: 0;

  &::after {
    display: none;
  }

  &:active,
  &:focus,
  &:hover,
  &.dropdown-toggle {
    background-color: #fff!important;
  }
}

.td-none {
  text-decoration: none !important;
}

.spin {
  animation: spin 1s linear forwards infinite;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
