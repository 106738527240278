.CenteredForm {
  width: 434px;
  background: #FFFFFF;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 50px;

  .formControl {
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;

    input {
      appearance: none;
      border: 0;
      background-color: rgba(255, 255, 255, 0);
      padding: 16px 16px 16px 24px;
      width: 100%;
      border-top: 1px solid #EEEFF2;

      &:focus {
        outline: none;
      }
    }

    &:first-child {
      input {
        border-top: 0;
      }
    }

    &.email {
      background-image: url(../../images/email.svg);
    }

    &.password {
      background-image: url(../../images/lock.svg);
    }

    .floating-error {
      font-size: 10px;
      position: absolute;
      right: 0;
      bottom: 6px;
      z-index: 2;
      color: #FF2D55;
    }
  }

  .inputGroup {
    background: #FFFAFA;
    box-shadow: 0px 12px 16px rgba(94, 99, 107, 0.08);
    border-radius: 13px;
    padding: 16px;
    margin-bottom: 35px;
  }

  button {
    appearance: none;
    border: 0;
    background: var(--btn-gradient);
    border-radius: 14px;
    padding: 8px 34px;
    font-weight: 700;
    color: #fff;
  }

  a {
    color: #FF3B4E8A;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      color: #FF3B4E8A;
      text-decoration: underline;
    }
  }

  h3 {
    span {
      color: #FF524B;
    }
  }
}
