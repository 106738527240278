.centered-layout-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.centered-layout-withfooter {
  height: calc(100vh - 116px);
}
