.Foldable-legend {
  font-size: 16px;
  color: #1A1B25;
  padding-left: 16px;
  position: relative;

  &::before {
    display: block;
    position: absolute;
    left: 0;
    content: '\25B6';
    color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0489532 0.385823C0.148455 0.152276 0.382863 0 0.642873 0H8.35713C8.61714 0 8.85155 0.152276 8.95105 0.385823C9.05055 0.619369 8.99555 0.888193 8.81169 1.06694L4.95457 4.81694C4.70352 5.06102 4.29648 5.06102 4.04543 4.81694L0.188306 1.06694C0.00445141 0.888193 -0.0505485 0.619369 0.0489532 0.385823Z' fill='%23797F8A'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.Foldable-open {
  .Foldable-legend {
    &::before {
      content: '\25BC';
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0489532 0.385823C0.148455 0.152276 0.382863 0 0.642873 0H8.35713C8.61714 0 8.85155 0.152276 8.95105 0.385823C9.05055 0.619369 8.99555 0.888193 8.81169 1.06694L4.95457 4.81694C4.70352 5.06102 4.29648 5.06102 4.04543 4.81694L0.188306 1.06694C0.00445141 0.888193 -0.0505485 0.619369 0.0489532 0.385823Z' fill='%23797F8A'/%3E%3C/svg%3E%0A");
    }
  }
}

.Foldable-clickable {
  cursor: pointer;
}
