.Tag {
  display: inline-block;
  padding: 1px 5px;
  border-radius:4px;
  background-color: #eeeefa;
  color: #64748B;
  margin-right: 4px;
  text-transform: uppercase;
}

.Tag-green {
  background-color: #D1FAE5;
  color: #059669;
}

.Tag-purple {
  background-color: #FAD1F1;
  color: #820596;
}

.Tag-blue {
  background-color: #D1EBFA;
  color: #053F96;
}

.Tag-orange {
  background-color: #FAEAD1;
  color: #BB610F;
}

.Tag-red {
  background-color: #FAD6D1;
  color: #BB0F0F;
}
