.SubNavigation {
  padding: 0;
  margin: 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      border-left: 1px solid #EEEFF2;
      padding: 10px 0;

      &:last-child {
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      a {
        display: block;
        text-decoration: none;
        color: #000;
        padding-left: 10px;
        border-left: 1px solid #EEEFF2;
        margin-left: -1px;
        
        &:hover {
          border-left-color: #000;
        }

        &.SubNavigation-subLink {
          padding-left: 16px;
        }

        &.active {
          font-weight: 700;
          color: #FF524B;
          border-left-color: #FF524B;
        }
      }
    }
  }
}

.Teachers-table {
  font-size: 14px;

  tr {
    border-bottom: 1px solid #EEEFF2;
    vertical-align: middle;
  }

  th {
    font-weight: 400;
    text-transform: uppercase;
  }

  th, td:not(.FoldableTableRow-arrow) {
    padding: 11px 16px !important;
  }

  td {
    &.gray {
      color: #797F8A;
    }
  }

  a {
    color: #797F8A;
    text-decoration: none;

    &:hover {
      color: #797F8A;
      text-decoration: underline;
    }
  }
}

.Teachers-table-checkbox {
  width: 30px;
}

.Teachers-table-calendar {
  width: 20%;
}

.Teachers-table-details {
  width: 80px;
}

.Checkbox {

  input[type=checkbox] {
    display: none;

    &:checked ~ span {
      background-image: url(../../images/checkbox-checked.svg);
    }
  }

  span {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background-image: url(../../images/checkbox.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.Teachers-table-info {
  color: #64748B;

  strong {
    color: #475569;
  }

  .row {
    justify-content: center;
    align-items: center;
  }
}

.Teachers-table-nofirstpadding {
  tr {
    td, th {
      &:not(.FoldableTableRow-arrow):first-child {
        padding-left: 0 !important;
      }
      &:last-child {
        text-align: right;
        padding-left: 0 !important;
      }
    }
  }
}

.Table-page-btns {
  text-align: right;
}

.Table-page-btn {
  appearance: none;
  display: inline-block;
  margin-left: 10px;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 10px;
  border: 1px solid #E2E8F0;
  color: #FF524B;

  &:disabled {
    color: #CBD5E1;
  }
}

.Teachers-total {
  color: #797F8A;
  font-size: 20px;
  font-weight: 400;
  margin-left: 16px;
}

.Table-actions {
  text-align: right;

  button {
    margin-left: 10px;
  }
}

.Teachers-checkedInfo {
  color: #797F8A;
  display: inline-block;
  margin-right: 10px;

  span {
    display: inline-block;
    border-right: 1px solid #797F8A;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  button {
    margin-left: 10px;
    padding-left: 0;
    padding-right: 0;
  }
}

.Table-user-image {
  width: 30px;
  height: 30px;
}
