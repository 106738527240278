.HomeFooter-wrapper {
  padding: 35px;
  color: #797F8A;
  line-height: 18px;

  span {
    font-size: 2rem;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 16px;
    padding-bottom: 2px;
  }

  a {
    color: #797F8A;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
