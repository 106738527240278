.NoResults-wrapper {
  display: flex;
  width: 100w;
  height: 100%;
  min-height: 400px;
  align-items: center;
  justify-content: center;
}

.NoResults-content {
  width: 260px;
}

.NoResults-text {
  font-size: 13px;
}
