.SubLinks {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid #EEEFF2;

  li {
    margin: 0 16px;

    a {
      display: block;
      padding: 10px 0;
      text-decoration: none;
      color: #000;

      &.active,
      &:hover {
        color: #FF524B;
        border-bottom: 1px solid #FF524B;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }
}