.HomeHeader-wrapper {
  text-align: right;
  padding: 35px;
}

.HomeHeader-login-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-right: 35px;

  &:hover {
    color: #000000;
  }
}

.HomeHeader-signup-btn {
  display: inline-block;
  background: var(--btn-gradient);
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}
