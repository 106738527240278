.Badge {
  display: inline-block;
  padding: 2px 14px;
  border-radius: 15px;
  background-color: #eeeefa;
  color: #64748B;
}

.Badge-success {
  background-color: #D1FAE5;
  color: #059669;
}
