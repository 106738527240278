.HeaderMenu {
  align-items: flex-end;

  .no-toggle {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }

  .no-padding {
    padding: 0;
    padding-right: 20px;
    text-align-last: left;
    line-height: 20px;
    margin-left: 10px;

    &::after {
      position: absolute;
      right: 0;
    }
  }
}

.HeaderMenu-user {
  margin-left: 20px;
}

.HeaderMenu-aligned {
  align-items: center;
  justify-content: flex-end;
}

.HeaderMenu-username {
  color: #000;
  font-size: 16px;
}

.HeaderMenu-userinfo {
  color: #5E6366;
  font-size: 14px;
}

.HeaderMenu-user-image {
  box-shadow: (0px 10px 20px rgba(0, 0, 0, 0.15));
  width: 48px;
  height: 48px;
}
