.SearchForm {
  background-color: #fff;
  border-radius: 25px;
  padding: 8px 10px;

  input {
    appearance: none;
    padding: 0;
    border: 0;
    width: 100%;
    font-size: 12px;

    &:focus {
      outline: none;
    }
  }
}

.SearchForm-icon {
  text-align: center;
}
