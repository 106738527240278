.User-info {
  font-size: 13px;
  position: sticky;
  left: 0;
  top: 16px;
}

.User-info-big {
  font-size: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #EEEFF2;

  a {
    text-decoration: none;
    
    &:hover {
      color: #797F8A;
      text-decoration: underline;
    }
  }
}

.Profile-user-image {
  width: 2rem;
  height: 2rem;
  max-width: 100%;
}

.edit-image {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;

  &:after {
    display: none;
    content: 'edit';
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .5);
  }

  &:hover {

    &:after {
      display: block;
    }
  }
}
