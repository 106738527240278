.Stripe-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;

  &::after {
    display: block;
    position: fixed;
    z-index: -1;
    background-color: #F6F8FA;
    content: '';
    width: 180%;
    height: 100%;
    top: -30%;
    left: -20%;
    transform: rotateZ(-10deg);
  }

  &.Stripe-lines {
    &::after {
      top: -50%;
    }
  }
}
