.Teacher-detailsblock-title {
  font-size: 16px;
  font-weight: 700;
}

.Teacher-detailsblock {
  padding-bottom: 10px;
  border-bottom: 1px solid #EEEFF2;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.Teachers-table-colored-links {
  a {
    color: #FF524B;
  }
}

.FoldableTableRow {
  cursor: pointer;

  &:hover {
    background-color: #EEEFF2;
  }
}

.FoldableTableRow-arrow {
  width: 30px;
  padding-left: 6px !important;
}

.FoldableTableRow-exposed {
  border-bottom: 0!important;
}
