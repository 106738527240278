.Navigation {
  padding: 16px 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #EEEFF2;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      display: block;
      margin-right: 16px;

      a {
        display: block;
        background-color: #fff;
        border-radius: 12px;
        text-decoration: none;
        color: #000;
        padding: 1px 14px;
        font-size: 14px;

        &.active {
          background-color: #FF524B;
          color: #fff;
        }

        &:hover:not(.active) {
          background-color: #EEEFF2;
          color: #000;
        }
      }
    }
  }
}
