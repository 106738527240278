.Home-wrapper {
  height: 100vh;
  justify-content: space-between;
}

.Home-content {
  justify-content: space-between;
  padding: 35px;

  div {
    width: 45%;
  }

  h1 {
    span {
      color: #FF524B;
    }
  }
}
